var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "constainer"
  }, [_c("div", {
    staticClass: "constainer-header"
  }, [_c("a-form", {
    attrs: {
      form: _vm.editorForm,
      labelAlign: "left"
    }
  }, [_c("a-form-item", {
    staticClass: "addpre",
    attrs: {
      label: "关联用户(关联用户必须已设置预发)",
      name: "hooduseruid"
    }
  }, [_c("a-select", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["hooduseruid", {
        rules: [{
          required: true,
          message: "请选择用户ID/用户名"
        }],
        initialValue: _vm.currentFormData.hooduseruid
      }],
      expression: "[\n            'hooduseruid',\n            {\n              rules: [\n                {\n                  required: true,\n                  message: '请选择用户ID/用户名',\n                },\n              ],\n              initialValue: currentFormData.hooduseruid,\n            },\n          ]"
    }],
    staticStyle: {
      width: "200px"
    },
    attrs: {
      "show-search": "",
      placeholder: "请输入搜索",
      "default-active-first-option": false,
      "show-arrow": true,
      "filter-option": false,
      "not-found-content": "暂无数据",
      loading: _vm.selectLoading
    },
    on: {
      search: _vm.handleSearch,
      change: _vm.handleChange,
      popupScroll: _vm.handlePopupScroll
    }
  }, _vm._l(_vm.useConnectLIst, function (d) {
    return _c("a-select-option", {
      key: d.hooduseruid
    }, [_vm._v(" " + _vm._s(d.hooduserusername || "-") + " ")]);
  }), 1)], 1), _c("a-form-item", {
    staticClass: "addpre",
    attrs: {
      label: "状态设置",
      prop: "istakeeffect"
    }
  }, [_c("a-radio-group", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["effectstatus", {
        rules: [{
          required: true,
          message: "请选择状态"
        }],
        initialValue: _vm.currentFormData.effectstatus
      }],
      expression: "[\n            'effectstatus',\n            {\n              rules: [\n                {\n                  required: true,\n                  message: '请选择状态',\n                },\n              ],\n              initialValue: currentFormData.effectstatus,\n            },\n          ]"
    }]
  }, [_c("a-radio", {
    attrs: {
      value: true
    }
  }, [_vm._v(" 生效中 ")]), _c("a-radio", {
    attrs: {
      value: false
    }
  }, [_vm._v(" 失效 ")])], 1)], 1), _c("a-form-item", {
    staticStyle: {
      display: "flex",
      "justify-content": "space-between"
    }
  }, [_vm.updateStatus == 0 ? _c("span", [_c("a-button", {
    staticClass: "btn btn_l",
    staticStyle: {
      background: "#f5f5f5",
      border: "0"
    },
    attrs: {
      size: "large"
    },
    on: {
      click: _vm.cancle
    }
  }, [_vm._v(_vm._s("取消"))]), _c("a-button", {
    staticClass: "btn btn_r",
    staticStyle: {
      "margin-left": "10px"
    },
    attrs: {
      size: "large",
      type: "primary",
      loading: _vm.submitLoading
    },
    on: {
      click: _vm.submit
    }
  }, [_vm._v(_vm._s(_vm.editId ? "保存" : "创建"))])], 1) : _vm._e(), _vm.updateStatus == 1 ? _c("span", [_c("a-button", {
    staticClass: "btn btn_l",
    staticStyle: {
      background: "#f5f5f5",
      border: "0"
    },
    attrs: {
      size: "large"
    },
    on: {
      click: _vm.cancle
    }
  }, [_vm._v(_vm._s("取消"))]), _c("a-button", {
    staticClass: "btn btn_r",
    staticStyle: {
      "margin-left": "10px"
    },
    attrs: {
      size: "large",
      type: "primary",
      loading: _vm.submitLoading
    },
    on: {
      click: _vm.submit
    }
  }, [_vm._v(_vm._s("确定"))])], 1) : _vm._e()])], 1)], 1)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };