import _objectSpread from "D:/projects/operation-management-background/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.push.js";
import Mixin from "@/utils/mixin";
import { columnsPerson } from "./config";
import { tradePreRecommendApi } from "@/api";
import AddPreRecommend from "./component/AddPreRecommend.vue";
export default {
  name: "tradePreRecommend",
  components: {
    AddPreRecommend: AddPreRecommend
  },
  data: function data() {
    return {
      updateVisible: false,
      editId: "",
      loading: false,
      columns: columnsPerson,
      total: 0,
      page: 1,
      size: 20,
      dataSource: [],
      versionTitle: "",
      addVersionVisible: false,
      updateStatus: "",
      //编辑数据
      currentFormData: {},
      delVisible: false
    };
  },
  mixins: [Mixin],
  created: function created() {
    this.getList();
  },
  methods: {
    copy: function copy() {
      this.toast("复制成功", "success");
    },
    handleStop: function handleStop(val) {
      this.editId = val.id;
      this.updateVisible = true;
    },
    handleUpdateCancle: function handleUpdateCancle() {
      this.updateVisible = false;
    },
    handleUpdateSubmitSuccess: function handleUpdateSubmitSuccess() {
      this.getList();
      this.updateVisible = false;
    },
    handleAddCancle: function handleAddCancle() {
      this.addVersionVisible = false;
    },
    handleAddSubmitSuccess: function handleAddSubmitSuccess() {
      this.getList();
      this.addVersionVisible = false;
    },
    handleUpdateDetail: function handleUpdateDetail(val) {
      this.versionTitle = val.status == 0 ? "创建" : "编辑";
      this.updateStatus = "1";
      this.editId = val.id;
      this.addVersionVisible = true;
    },
    getList: function getList() {
      var _this = this;
      this.loading = true;
      var _params = {
        size: this.size,
        page: this.page
      };
      tradePreRecommendApi.findpage(_params).then(function (res) {
        _this.loading = false;
        _this.dataSource = res.list;
        _this.total = res.total;
      }).catch(function () {
        _this.dataSource = [];
      }).finally(function () {
        setTimeout(function () {
          _this.loading = false;
        }, 3000);
      });
    },
    onPageSizeChange: function onPageSizeChange(page) {
      this.page = page;
      this.getList();
    },
    onShowSizeChange: function onShowSizeChange(page, size) {
      this.page = 1;
      this.size = size;
      this.getList();
    },
    handleAdd: function handleAdd() {
      this.currentFormData = {};
      this.editId = "";
      this.updateStatus = "";
      this.versionTitle = "创建";
      this.addVersionVisible = true;
    },
    handleButton: function handleButton(type, val) {
      this.currentFormData = _objectSpread({}, val);
      if (type == "edit") {
        this.handleUpdateDetail(val);
      } else {
        this.delVisible = true;
      }
    },
    handleDelete: function handleDelete(val) {
      var _this2 = this;
      tradePreRecommendApi.del({
        id: val.id
      }).then(function () {
        _this2.getList();
        _this2.toast("操作成功", "success");
      }).catch(function () {
        _this2.toast("操作失败", "error");
      });
    },
    confirm: function confirm() {
      this.handleDelete(this.currentFormData);
      this.delVisible = false;
    },
    cancle: function cancle() {
      this.delVisible = false;
      // this.$message.error('Click on No');
    },
    toUserDetail: function toUserDetail(uid) {
      if (!uid) {
        this.$message.error("用户信息有误！");
        return;
      }
      var path = "/user/detail?uid=".concat(uid);
      this.$router.push(path);
    }
  }
};